import { React, useState, useEffect } from 'react'
import { Toast, Radio, Picker } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons'
import '../Pay/pay.css'
import _ from 'lodash'
import { selectPay, createStarsOrder } from '../../request/api'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NewPay() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false)//选择器显示
  const [starPay, setStarPay] = useState(false)//新支付显示
  const [erc20, setErc20] = useState(false)//erc20显示
  const [data, setData] = useState([])//erc20数据
  const [list1, setlist1] = useState([])
  const [list2, setlist2] = useState([])
  const [radioValue, setRadioValue] = useState('1')//类型单选
  const [inputVal, setInputVal] = useState('')//金额
  const [value, setValue] = useState('')//测试字
  const [maxValue, setMaxValue] = useState('')//最大值
  const [minValue, setMinValue] = useState('')//最小值
  const [textVal, setTextVal] = useState(t('cz3'))
  const getData = async () => {
    const res = await selectPay({})
    if (res.data.has_star == 1) {
      setStarPay(true)
    }
    if (res.data.erc20 != '') {
      setErc20(true)
      setData(res.data.erc20)
    }
    setlist2(res.data.fiat)
    let arr = []
    arr.push(res.data.moonfiat)
    setlist1(arr)
  }
  const submit = () => {
    if (radioValue == 1) {
      navigate('/UserPay')//原始充值页
    } else if (radioValue == 3) {
      navigate(`/ErcPay?data=${data}`)//原始充值页+参数
    } else {
      if (inputVal !== '' && value !== '') {
        if (inputVal > maxValue) {
          Toast.show({
            content: `${t('t17')} ${maxValue}`,
            duration: 2500
          })
          return false
        } else if (inputVal < minValue) {
          Toast.show({
            content: `${t('t18')} ${minValue}`,
            duration: 2500
          })
          return false
        } else {
          Toast.show({
            icon: 'loading',
            content: t('t4'),
            duration: 800
          })
          createStarsOrder({
            amount: inputVal,
            fiat_currency: value[0]
          }).then(res => {
            if (res.code == 0) {
              Toast.show({
                icon: 'fail',
                content: res.info,
                duration: 2500
              })
              return false
            } else {
              window.open(res.data)
            }
          })
        }
      }
    }

  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className='newPay'>
      <div className='header'>
        <div className='backIcon' onClick={() => { navigate('/User') }}>
          <LeftOutline />
        </div>
        <p>{t('cz4')}</p>
      </div>
      <div className='newpay_box'>
        <Radio.Group defaultValue={radioValue} onChange={(e) => { setRadioValue(e); }}>
          <div className='PayType_list'>
            <div>
              <img src="/assets/index/trc20.png" alt="" />
              <p>usdt</p>
            </div>
            <Radio value='1' />
          </div>
          {starPay && <div className='PayType_list'>
            <div>
              <img src="/assets/index/starspay.png" alt="" />
              <p>starspay</p>
            </div>
            <Radio value='2' />
          </div>}
          {erc20 && <div className='PayType_list'>
            <div>
              <img src="/assets/index/erc20.png" alt="" />
              <p>erc20</p>
            </div>
            <Radio value='3' />
          </div>}
        </Radio.Group>
      </div>
      {radioValue == 2 && <div className='startModel'>
        <div className='newPay_moneyInput'>
          <p>{t('d6')}</p>
          <input type="text" onChange={(e) => { setInputVal(e.target.value) }} />
        </div>
        <div className='newPay_model' onClick={() => { setVisible(true) }}>
          <p>{t('cz3')}</p>
          <p>{textVal}</p>
        </div>
        <Picker
          columns={list1}
          cancelText={t('ty2')}
          confirmText={t('ty1')}
          visible={visible}
          onClose={() => {
            setVisible(false)
          }}
          onConfirm={v => {
            setTextVal(v)
            setValue(v)
            let filterValue = list2.filter(item => {
              return item.name == v
            })
            setMaxValue(filterValue[0].max)
            setMinValue(filterValue[0].min)
          }}
        />
      </div>}
      <div className='submit_Pay' onClick={submit}>
        <p>{t('ty3')}</p>
      </div>
    </div>
  )
}
