import i18n from "i18next";
import { en } from "./locales/en-us";
import { zh } from "./locales/zh-cn";
import { es } from "./locales/es-es";
import { pl } from "./locales/pl-pl";
import { cs } from "./locales/cs-cs";
import { da } from "./locales/da-da";
import { el } from "./locales/el-el";
import { ro } from "./locales/ro-ro";
import { sk } from "./locales/sk-sk";
import { de } from "./locales/de-de";
import { it } from "./locales/it-it";
import { jp } from "./locales/jp-jp";
import { tr } from "./locales/tr-tr";
import { ar } from "./locales/ae-ae";
import { nl } from "./locales/nl-nl";
import { bn } from "./locales/bn-bn";
import { kr } from "./locales/kr-kr";

import { initReactI18next } from 'react-i18next';
const resources = {
    en: {
        translation: en
    },
    zh: {
        translation: zh
    },
    es: {
        translation: es
    },
    pl: {
        translation: pl
    },
    cs: {
        translation: cs
    },
    da: {
        translation: da
    },
    el: {
        translation: el
    },
    ro: {
        translation: ro
    },
    sk: {
        translation: sk
    },
    de: {
        translation: de
    },
    it: {
        translation: it
    },
    jp: {
        translation: jp
    },
    tr: {
        translation: tr
    },
    ar: {
        translation: ar
    },
    nl: {
        translation: nl
    },
    bn: {
        translation: bn
    },
    kr: {
        translation: kr
    },
};
const defaultLang = window.localStorage.getItem('lang')

i18n.use(initReactI18next)
    .init({
        resources,
        //选择默认语言
        lng: defaultLang ? defaultLang : 'en',
        interpolation: {
            escapeValue: false,
        },
    })
export default i18n;