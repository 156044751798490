import React from 'react'
import { LeftOutline } from 'antd-mobile-icons'
import './about.css'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function About() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className=''>
            <div className='header'>
                <div className='backIcon' onClick={() => { navigate('/') }}>
                    <LeftOutline />
                </div>
                <p>{t('s3')}</p>
            </div>
            <div className='about_box'>
                <img src="/assets/about/about9.png" ></img>
                <p></p>
                <img src="/assets/about/about10.png" ></img>
                <p>Name: RAYSBOT PRIVATE LIMITED</p>
                <p>UEN: 202231656D</p>
                <p>Registration Date: 07/09/2022</p>
                <p>Next review date: 29/08/2026</p>
                <p>Status: Registered</p>
                <p>Type: DEVELOPMENT OF SOFTWARE AND APPLICATIONS (EXCEPT GAMES AND CYBERSECURITY)</p>
                <p>Registered Office: 60 PAYA LEBAR ROAD, #12-03, PAYA LEBAR SQUARE, SINGAPORE 409051</p>
                <p>Regulatory Authority: Singapore Government Agency</p>
                <p><b>Check the website:</b></p>
                <p><a href="https://www.tis.bizfile.gov.sg/ngbtisinternet/faces/oracle/webcenter/portalapp/pages/TransactionMain.jspx?_adf.ctrl-state=15eat1anfz_4&amp;_afrWindowId=null&amp;selectedETransId=dirSearch"
                    target="_blank">
                    https://www.tis.bizfile.gov.sg/ngbtisinternet/faces/oracle/webcenter/portalapp/pages/TransactionMain.jspx?_adf.ctrl-state=15eat1anfz_4&amp;_afrWindowId=null&amp;selectedETransId=dirSearch
                </a>
                </p>
                <p></p>
                <img src="/assets/about/about1.png" ></img>
                <p></p>
                <img src="/assets/about/about2.png" ></img>
                <p>Name: RAYSBOT PROPRIETARY LIMITED</p>
                <p>ACN: 662 015 714</p>
                <p>Registration date: 29/08/2022</p>
                <p>Next review date: 29/08/2026</p>
                <p>Status: Registered</p>
                <p>Type: Australian Proprietary Company, Limited By Shares</p>
                <p>Locality of registered office: MELBOURNE VIC 3000</p>
                <p>Regulator: Australian Securities &amp; Investments Commission</p>
                <p><b>Check the website:</b></p>
                <p>
                    <a href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx?_adf.ctrl-state=h7mpkhymp_41" target="_blank">https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx?_adf.ctrl-state=h7mpkhymp_41</a>
                </p>
                <p></p>
                <img src="/assets/about/about3.png" ></img>
                <p></p>
                <img src="/assets/about/about4.png" ></img>
                <p>Name: RAYSBOT LIMITED</p>
                <p>ACN: 1000297027</p>
                <p>Registration Date: 28/08/2022</p>
                <p>Next review date: 29/08/2026</p>
                <p>Status: Registered</p>
                <p>Type: Ontario Business Corporation</p>
                <p>Registered Office: Toronto, Ontario, Canada</p>
                <p>Regulatory Agency: Toronto Financial Services Agency, Canada</p>
                <p><b>Check out the website:</b></p>
                <p><a href="https://www.appmybizaccount.gov.on.ca/onbis/master/viewInstance/view.pub?id=3abd3bce3cc0ad2a9cb78dda1345c1f8897cb55f5ff8df9e&amp;_timestamp=2528604023173086"
                    target="_blank">https://www.appmybizaccount.gov.on.ca/onbis/master/viewInstance/view.pub?id=3abd3bce3cc0ad2a9cb78dda1345c1f8897cb55f5ff8df9e&amp;_timestamp=2528604023173086</a>
                </p>
                <p></p>
                <img src="/assets/about/about5.png" ></img>
                <p></p>
                <img src="/assets/about/about6.png" ></img>
                <p>Name: Raysbot Limited</p>
                <p>ACN: 20221841924</p>
                <p>Registration Date: 27/08/2022</p>
                <p>Next review date: 29/08/2026</p>
                <p>Status: Registered</p>
                <p>Type: Company</p>
                <p>Registered Office: 1755 Telstar Drive, Colorado Springs, CO 80920, United States</p>
                <p>Regulator: Colorado</p>
                <p><b>Check out the website:</b></p>
                <p><a href="https://www.coloradosos.gov/biz/BusinessEntityCriteriaExt.do"
                    target="_blank">https://www.coloradosos.gov/biz/BusinessEntityCriteriaExt.do</a></p>
                <p></p>
                <img src="/assets/about/about7.png" ></img>
                <p></p>
                <img src="/assets/about/about8.png" ></img>
                <p>Name: RAYSBOT LIMITED</p>
                <p>ACN: 14324316</p>
                <p>Registration Date: 30 August 2022</p>
                <p>Next review date: 29 August 2026</p>
                <p>Status: Registered</p>
                <p>Type: Private limited company</p>
                <p>Registered Office: 77 Farringdon Road, London, United Kingdom, EC1M 3JU</p>
                <p>Regulators: Administration of financial markets</p>
                <p>Other activities auxiliary to insurance and pension funding</p>
                <p>Fund management activities</p>
                <p>Combined office administrative service activities</p>
                <p><b>Check out the website:</b></p>
                <p><a href="https://find-and-update.company-information.service.gov.uk/"
                    target="_blank">https://find-and-update.company-information.service.gov.uk/</a></p>
                <p></p>
                <p>RAYSBOT LIMITED is expanding offices in more regions. Please wait for more business
                    licenses and related information</p>
                <p>Please provide your contact information and brief information. Let us know how we can
                    help you and we'll get back to you as soon as possible</p>
                <p><b>RAYSBOT LIMITED Official Email:</b></p>
                <p><a href="mailto:raysbotlimited@gmail.com">raysbotlimited@gmail.com</a></p>
                <p>All certificate attribution is limited to RAYSBOT LIMITED. Do not use this certificate
                    for unlawful advertising. Otherwise the company will take legal action against you. You will bear legal
                    responsibility.</p>
                <p></p>
            </div>
        </div>
    )
}
