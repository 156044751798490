import { React, useState, useEffect } from 'react'
import Tabber from '../copmonets/Tabber'
import { Toast, Rate } from 'antd-mobile'
import './trade.css'
import _ from 'lodash'
import { getInfo, confirmOrderTwo, matchOrder } from '../request/api'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Trade() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const throttle = _.throttle;
    const [userInfo, setUserInfo] = useState({})
    const [data, setData] = useState([])
    const [appear, setAppear] = useState(false)
    const [need, setNeed] = useState(false)
    const [orderId, setOrderId] = useState('')

    const getdata = async () => {
        const res = await getInfo({})
        if (res.code == 0) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('warning');
            localStorage.removeItem('tan');
            localStorage.removeItem('tabber');
            Toast.show({
                icon: 'fail',
                content: res.info,
            })
            setTimeout(() => {
                window.location.hash = "/login"
            }, 2500);
        }
        setUserInfo(res.data)
    }
    const submit = async () => {
        Toast.show({
            icon: 'loading',
            content: t('t4'),
            duration: 1000,
        })
        const res = await matchOrder({})
        if (res.code == 1) {
            setData(res.data)
            setOrderId(res.data.order_id)
            setAppear(true)
            if (Number(userInfo.balances) < Number(res.data.goods.price)) {
                setNeed(true)
            } else {
                setNeed(false)
            }
        } else if (res.code == 101) {
            Toast.show({
                content: res.info,
            })
            setTimeout(() => {
                navigate('/Order')
            }, 1500);
        } else if (res.code == 102) {
            Toast.show({
                content: res.info,
            })
        }
    }
    const startPops = throttle(() => {
        submit()
    }, 1500, { 'trailing': false })

    let star = 0;
    const starChange = (val) => {
        star = val
    }
    const submitOrder = throttle(() => {
        if (star < 3) {
            Toast.show({
                content: t('t6'),
            })
        } else {
            Toast.show({
                icon: 'loading',
                content: t('t4'),
            })
            confirmOrderTwo({ order_id: orderId }).then(res => {
                if (res.code == 1) {
                    Toast.show({
                        icon: 'success',
                        content: t('t7'),
                    })
                    setTimeout(() => {
                        window.location.reload()
                    }, 1200);
                } else {
                    Toast.show({
                        content: res.info,
                    })
                    setTimeout(() => {
                        setAppear(false)
                        navigate('/Order')
                    }, 1000);
                }

            })
        }
    }, 1500, { 'trailing': false })
    useEffect(() => {
        getdata()
    }, [])
    return (
        <div className='Trade'>
            <div className='header'>{t('n3')}</div>
            <div className='Trade_box'>
                <div className='Trade_top'>
                    <p>{t('j1')}</p>
                    <div>
                        <p>USDT:</p>
                        <p>{userInfo.balances}</p>
                    </div>
                </div>
                <div className='Trade_title'>
                    <div className='Trade_text'>
                        <p>{t('j2')}</p>
                        <p>{t('j3')}</p>
                    </div>
                    <div className='Trade_fun'>
                        <div>
                            <p>{t('j6')}</p>
                            <p>{userInfo.finish_num}</p>
                        </div>
                        <div>
                            <p>{t('j7')}</p>
                            <p>{userInfo.task_num}</p>
                        </div>
                    </div>
                    <div className='Trade_start' onClick={startPops}>
                        <p>{t('j4')}</p>
                    </div>
                    <div className='Trade_result'>
                        <div>
                            <img src="/assets/index/shouyi.svg" alt="" />
                            <p>{t('d8')}:</p>
                            <div className='flex_bug'>
                                <p>{userInfo.rate}</p>
                                <p>%</p>
                            </div>
                        </div>
                        <div>
                            <img src="/assets/index/daole.svg" alt="" />
                            <p>{t('j5')}:</p>
                            <div className='flex_bug'>
                                <p style={{ color: 'rgb(255, 83, 83)' }}>{userInfo.today_win}</p>
                                <p>USDT</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {appear && < div className='modelWindow'>
                <div className='window_center'>
                    <div className='order_window'>
                        <div>
                            <div className='card_tops'>
                                <img src={data.goods.pic} alt="" />
                                <div className='list_texts' style={{ margin: '0 0 0 10px' }}>
                                    <p>{data.goods.name}</p>
                                    <div>
                                        <p>{data.goods.price}</p>
                                        <p>X{data.goods.num}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='card_down'>
                                <div>
                                    <p>{t('d5')}</p>
                                    <p>{data.goods.create_at}</p>
                                </div>
                                <div>
                                    <p>{t('d6')}</p>
                                    <p>{data.goods.price}</p>
                                </div>
                                {need && <div>
                                    <p>{t('d9')}</p>
                                    <p>{(Number(userInfo.balances) - Number(data.goods.price)).toFixed(2)}</p>
                                </div>}
                                <div>
                                    <p>{t('d8')}</p>
                                    <p style={{ color: '#ff5353' }}>{data.income}</p>
                                </div>
                            </div>
                        </div>
                        <div className='capital_evaluate'>
                            <p>{t('d10')}</p>
                            <Rate style={{
                                '--star-size': '22px',
                                '--active-color': '#ff0000',
                            }} onChange={(val) => { starChange(val) }} />
                        </div>
                    </div>
                    <div className='pop_but_trade' onClick={() => { submitOrder() }}>
                        <p>{t('ty3')}</p>
                    </div>
                    <div className='pop_but_trade cancel' onClick={() => { setAppear(false) }}>
                        <p>{t('ty2')}</p>
                    </div>
                </div>
            </div>}
            <Tabber></Tabber>
        </div >
    )
}
