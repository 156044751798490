import { React, useState } from 'react'
import { InfiniteScroll, ErrorBlock } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons'
import '../Record/record.css'
import { getRecharge } from '../../request/api'
import { useNavigate } from "react-router-dom";
import { sleep } from 'antd-mobile/es/utils/sleep'
import { useTranslation } from "react-i18next";

export default function Recharge() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [noData, setNoData] = useState(false)
  async function mockRequest() {
    setNoData(false)
    const res = await getRecharge({ page: pageNum, type: 2 })
    let list = data
    setData(list.concat(res.data))
    if (res.data.length == 0 && pageNum == 1) {//一条数据都没有
      setNoData(true)
      setData([])
      setPageNum(1)
      return []
    }
    if (res.data.length < 10 && pageNum == 1) {
      await sleep(2000)
      setPageNum(pageNum + 1)
      return res.data
    } else {
      await sleep(2000)
      setPageNum(pageNum + 1)
      return res.data
    }

  }
  async function loadMore() {
    const append = await mockRequest()
    setHasMore(append.length >= 10)//是否继续加载
  }
  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>{t('t4')}</span>
          </>
        ) : (
          <span>--- {t('t25')} ---</span>
        )}
      </>
    )
  }
  return (
    <div className='funds'>
      <div className='header'>
        <div className='backIcon' onClick={() => { navigate('/User') }}>
          <LeftOutline />
        </div>
        <p>{t('g8')}</p>
      </div>
      {noData && <ErrorBlock
        title={t('t25')}
        image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
        style={{
          '--image-height': '150px',
        }}
        description={<span></span>}
      >
      </ErrorBlock>}
      {!noData && <div>
        <div className='detail_box'>
          {data.map((item, index) => (
            <div key={index}>
              <div className='detail_list'>
                <div>
                  <p>{t('d6')}</p>
                  <p>{item.type == 1 ? t('mx5') : item.type == 2 ? t('mx5') : t('mx5')}</p>
                </div>
                <p style={{ color: '#0d8e31' }}>{Number(item.amount) > 0 ? '+' : ''}{Number(item.amount)}</p>
                <div>
                  <p>{t('mx4')}</p>
                  <p>{item.create_at}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
          <InfiniteScrollContent hasMore={hasMore} />
        </InfiniteScroll>
      </div>}
    </div>
  )
}
