import { React, useState, useEffect } from 'react'
import Tabber from '../copmonets/Tabber'
import { ErrorBlock, Toast, Modal, Rate } from 'antd-mobile'
import './order.css'
import _ from 'lodash'
import { getInfo, confirmOrderTwo, orderList } from '../request/api'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function Order() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const throttle = _.throttle;
    const [noData, setNoData] = useState(false)
    const [active, setActive] = useState(1)
    const [data, setData] = useState([])
    const [orderMoney, setOrderMoney] = useState()
    const getMoney = async () => {
        const val = await getInfo({})
        if (val.code == 0) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('warning');
            localStorage.removeItem('tan');
            localStorage.removeItem('tabber');
            Toast.show({
                icon: 'fail',
                content: val.info,
            })
            setTimeout(() => {
                window.location.hash = "/login"
            }, 2500);
        }
        if (val.data.freeze_gec != '') {
            setOrderMoney((Number(val.data.balances) - Number(val.data
                .freeze_gec)).toFixed(2))
        } else {
            setOrderMoney(val.data.balances)
        }
    }
    const getData1 = async (i) => {
        Toast.show({
            icon: 'loading',
            content: t('t4'),
            duration: 1000,
        })
        const res = await orderList({ type: i })
        if (res.data.length == 0) {
            setNoData(true)
        } else {
            setNoData(false)
            setData(res.data)
        }
    }
    const Tab = throttle((i) => {
        setData([])
        setNoData(false)
        setActive(i)
        getData1(i)
    }, 1500, { 'trailing': false })
    let star = 0;
    const starChange = (val) => {
        star = val
    }
    const submitOrder = throttle(() => {
        if (star < 3) {
            Toast.show({
                content: t('t6'),
            })
        } else {
            Toast.show({
                icon: 'loading',
                content: t('t4'),
            })
            confirmOrderTwo({ order_id: data.order_id }).then(res => {
                if (res.code == 103) {
                    Toast.show({
                        content: res.info,
                    })
                } else {
                    Toast.show({
                        icon: 'success',
                        content: t('t7'),
                    })
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
            })
        }
    }, 1500, { 'trailing': false })
    useEffect(() => {
        getMoney()
        getData1(1)
    }, [])
    return (
        <div className='order'>
            <div className='header'>{t('n2')}</div>
            <div className='order_tab'>
                <p className={active == 1 ? 'order_complete' : 'order_undone'} onClick={() => { Tab(1) }}>{t('d1')}</p>
                <p className={active == 2 ? 'order_complete' : 'order_undone'} onClick={() => { Tab(2) }}>{t('d2')}</p>
            </div>
            <div className='order_card1'>
                {noData && <ErrorBlock
                    title={t('d3')}
                    image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
                    style={{
                        '--image-height': '150px',
                    }}
                    description={<span></span>}
                >
                </ErrorBlock>}
                <div className='dataList_order'>
                    {
                        data.name && <div className='order_list' onClick={() => {
                            Modal.show({
                                showCloseButton: true,
                                content: (
                                    <div>
                                        <div className='order_window'>
                                            <div>
                                                <div className='card_tops'>
                                                    <img src={data.pic} alt="" />
                                                    <div className='list_texts' style={{ margin: '0 0 0 10px' }}>
                                                        <p>{data.name}</p>
                                                        <div>
                                                            <p>{data.price}</p>
                                                            <p>X{data.num}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card_down'>
                                                    <div>
                                                        <p>{t('d6')}</p>
                                                        <p>{data.price}</p>
                                                    </div>
                                                    <div>
                                                        <p>{t('d8')}</p>
                                                        <p style={{ color: '#ff5353' }}>{data.income}</p>
                                                    </div>
                                                    <div>
                                                        <p>{t('d9')}</p>
                                                        <p>{orderMoney}</p>
                                                    </div>
                                                    {data.task_num != 0 && <div>
                                                        <p>{t('d7')}</p>
                                                        <p>{data.task_num}</p>
                                                    </div>}
                                                    <div>
                                                        <p>{t('d5')}</p>
                                                        <p>{data.create_at}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='capital_evaluate'>
                                                <p>{t('d10')}</p>
                                                <Rate style={{
                                                    '--star-size': '22px',
                                                    '--active-color': '#ff0000',
                                                }} onChange={(val) => { starChange(val) }} />
                                            </div>
                                        </div>
                                        <div className='pop_but' onClick={() => { submitOrder() }}>
                                            <p>{t('ty3')}</p>
                                        </div>
                                    </div>
                                ),
                            })
                        }}>
                            <div className='card_tops'>
                                <img src={data.pic} alt="" />
                                <div className='list_texts'>
                                    <p>{data.name}</p>
                                    <div>
                                        <p>{data.price}</p>
                                        <p>X{data.num}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='card_down'>
                                <div>
                                    <p>{t('d5')}</p>
                                    <p>{data.create_at}</p>
                                </div>
                                <div>
                                    <p>{t('d6')}</p>
                                    <p>{data.price}</p>
                                </div>
                                <div>
                                    <p>{t('d9')}</p>
                                    <p>{orderMoney}</p>
                                </div>
                                {data.task_num != 0 && <div>
                                    <p>{t('d7')}</p>
                                    <p>{data.task_num}</p>
                                </div>}
                                <div>
                                    <p>{t('d8')}</p>
                                    <p style={{ color: '#ff5353' }}>{data.income}</p>
                                </div>
                                <p style={{ color: 'red', textAlign: 'end' }}>{t('d1')}</p>
                            </div>
                        </div>
                    }
                    {
                        active === 2 && data.map((item, index) => {
                            return (
                                <div className='order_list' key={index}>
                                    <div className='card_tops'>
                                        <img src={item.goods_pic} alt="" />
                                        <div className='list_texts'>
                                            <p>{item.goods_name}</p>
                                            <div>
                                                <p>{item.amount}</p>
                                                <p>X{item.num}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card_down'>
                                        <div>
                                            <p>{t('d4')}</p>
                                            <p>{item.order_no}</p>
                                        </div>
                                        <div>
                                            <p>{t('d5')}</p>
                                            <p>{item.create_at}</p>
                                        </div>
                                        <div>
                                            <p>{t('d6')}</p>
                                            <p>{item.amount}</p>
                                        </div>
                                        {item.task_num != 0 && <div>
                                            <p>{t('d7')}</p>
                                            <p>{item.task_num}</p>
                                        </div>}
                                        <div>
                                            <p>{t('d8')}</p>
                                            <p style={{ color: '#ff5353' }}>{item.income}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Tabber></Tabber>
        </div>
    )
}
