import request from './http'
//首页
export const getInfo = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/getInfo', val).then(res => {
            resolve(res);
        })
    })
};
export const getBanner = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/getBanner', val).then(res => {
            resolve(res);
        })
    })
};
export const getService = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/getService', val).then(res => {
            resolve(res);
        })
    })
};
//登录、注册
export const login = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/login', val).then(res => {
            resolve(res);
        })
    })
};
export const register = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/register', val).then(res => {
            resolve(res);
        })
    })
}
//订单
export const orderList = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/orderList', val).then(res => {
            resolve(res);
        })
    })
}
export const confirmOrderTwo = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/confirmOrderTwo', val).then(res => {
            resolve(res);
        })
    })
}
//匹配订单
export const matchOrder = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/matchOrder', val).then(res => {
            resolve(res);
        })
    })
}
//团队
export const receiveBonus = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/receiveBonus', val).then(res => {
            resolve(res);
        })
    })
}
export const getTeam = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/getTeam1', val).then(res => {
            resolve(res);
        })
    })
}
//修改信息
export const updateUserInfo = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/updateUserInfo', val).then(res => {
            resolve(res);
        })
    })
}
//提现
export const withdraw = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/withdraw', val).then(res => {
            resolve(res);
        })
    })
}
//新支付
export const selectPay = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/selectPay', val).then(res => {
            resolve(res);
        })
    })
}
export const createStarsOrder = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/createStarsOrder', val).then(res => {
            resolve(res);
        })
    })
}
//充值
export const recharge = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/recharge', val).then(res => {
            resolve(res);
        })
    })
}
//记录页*3
export const getBalances = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/getBalances', val).then(res => {
            resolve(res);
        })
    })
}
export const getWithdraw = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/getWithdraw', val).then(res => {
            resolve(res);
        })
    })
}
export const getRecharge = (val) => {
    return new Promise((resolve, reject) => {
        request.post('/api/getRecharge', val).then(res => {
            resolve(res);
        })
    })
}