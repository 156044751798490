import { React, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { TabBar } from 'antd-mobile'
import {
    AppOutline,
    UnorderedListOutline,
    CheckShieldOutline,
    TeamOutline,
    UserOutline,
} from 'antd-mobile-icons'
import '../assets/css/componets.css'
import { useTranslation } from "react-i18next";
// let timeChange;
export default function Nav() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        console.log();
    }, [location]);
    useEffect(() => {
        if (location.pathname.slice(1) == '') {
            localStorage.setItem('tabber', '/')
        } else {
            localStorage.setItem('tabber', location.pathname.slice(1))
        }
    }, []);
    const tabs = [
        {
            key: '/',
            title: t('n1'),
            icon: <AppOutline />,
        },
        {
            key: 'Order',
            title: t('n2'),
            icon: <UnorderedListOutline />,
        },
        {
            key: 'Trade',
            title: t('n3'),
            icon: <CheckShieldOutline />,
        },
        {
            key: 'Team',
            title: t('n4'),
            icon: <TeamOutline />,
        },
        {
            key: 'User',
            title: t('n5'),
            icon: <UserOutline />,
        },
    ]
    const jumpPage = (event) => {
        localStorage.setItem('tabber', event)
        switch (event) {
            case '/':
                navigate('/')
                break;
            case 'Order':
                navigate('/Order')
                break;
            case 'Trade':
                navigate('/Trade')
                break;
            case 'Team':
                navigate('/Team')
                break;
            case 'User':
                navigate('/User')
                break;
        }
    }
    return (
        <div className='tabber'>
            <TabBar activeKey={localStorage.getItem('tabber')} onChange={(event) => { jumpPage(event) }}>
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
        </div>
    )
}
